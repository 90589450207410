import { useEffect } from "react";
import CodeBlock from "../components/CodeBlock";
import profileImage from "../image/choion.jpg";

const ContactPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="contact-page">
      <div className="contact-page container">
        <div className="contact-profile-container">
          <div className="contact-profile-image-container">
            <img src={profileImage} alt="" />
          </div>
          <div className="contact-profile-content-container">
            <h1>CHOI ON</h1>
            <h3>Frontend Software Engineer</h3>
            <p className="contact-profile-intro">
              Hi, I'm a computer science graduate from University of London @
              SIM GE Singapore with strong professional experience.
            </p>
            <p className="contact-profile-intro">
              Born and raised in South Korea, I moved to Singapore at the age of
              15. In the first year of the bachelor's degree, I realised that
              while traditional education could give me professional knowledge
              of computer science, it was not enough to equip me with the skills
              to solve problems arising from an ever-changing world. The idea
              was the beginning, and it made me want to know more about the
              problems faced by the economy and how industry handles them with
              many talented software engineers.
            </p>
            <p className="contact-profile-intro">
              As a software engineer, my mission is to translate user-centred
              designs into problem solutions.
            </p>
            <p className="contact-profile-intro">
              The best way to contact me is via email{" "}
              <span style={{ color: "#087EA4", fontWeight: "bold" }}>
                choi034@mymail.sim.edu.sg
              </span>
            </p>
          </div>
        </div>
        {/* <CodeBlock /> */}
      </div>
    </div>
  );
};

export default ContactPage;
