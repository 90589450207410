import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BlogPage from "./pages/BlogPage";
import ProjectsPage from "./pages/ProjectsPage";
import ContactPage from "./pages/ContactPage";
import PostDetailScreen from "./pages/PostDetailScreen";

import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import BlogDetailScreen from "./pages/BlogDetailScreen";

const App = () => {
  return (
    <Router>
      <Header />

      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/projects/detail/:id" element={<PostDetailScreen />} />
        <Route path="/blogs/detail/:id" element={<BlogDetailScreen />} />
      </Routes>

      <Footer />
    </Router>
  );
};

export default App;
