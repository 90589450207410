import lineBreaker from "../image/linebreak.png";

const LineBreak = () => {
  return (
    <div className="line-breaker-container">
      <img src={lineBreaker} alt="" />
    </div>
  );
};

export default LineBreak;
