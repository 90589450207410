import { useEffect } from "react";
import PostSection from "../components/PostSection";
import { blogData } from "../data";
import { useParams } from "react-router-dom";

const BlogDetailScreen = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  // Convert id to a number
  const projectId = parseInt(id, 10);

  // Find the project with the matching id
  const selectedBlog = blogData.find((project) => project.id === projectId);

  return (
    <div id="post-detail">
      <div className="post-detail container">
        <div className="post-detail-hero-section-container">
          <h1>{selectedBlog.title && selectedBlog.title}</h1>
          <p>{selectedBlog.subtitle && selectedBlog.subtitle}</p>
          <h3>{selectedBlog.date && selectedBlog.date}</h3>
          <p>{selectedBlog.length && selectedBlog.length}</p>
        </div>
        <div className="post-detail-main-image-container">
          <img src={selectedBlog.main_image} alt="" />
        </div>

        <div className="post-detail-main-content-container">
          {selectedBlog.sections.map((item) => (
            <PostSection
              section_title={item.section_title}
              section_subtitle={item.section_subtitle}
              section_image={item.section_image}
              section_mobile={item.section_mobile}
              section_content={item.section_content}
              section_bullets={item.section_bullets}
              section_codes={item.section_codes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetailScreen;
