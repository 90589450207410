import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import blurBg from "../image/blur-bg.png";
import { useEffect, useState } from "react";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setScrolled(true);
        setMobileMenuOpen(false);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
      <div id="navbar" className={`${scrolled ? "fixed-navbar" : ""}`}>
        <div className="navbar container">
          <div className="hamburger-menu" onClick={toggleMobileMenu}>
            <FeatherIcon icon={mobileMenuOpen ? "x" : "menu"} />
          </div>
          <div className="logo-menu-container">
            <div className="logo-container">
              <Link
                to={"/"}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={closeMobileMenu}
              >
                <h1>
                  CHOI
                  <span
                    style={{
                      background: "#FFAF36",
                      padding: "0 5px 0 0",
                      color: "#fff",
                    }}
                  >
                    ON
                  </span>
                </h1>
              </Link>
            </div>

            <ul
              className={`nav-menu-container ${mobileMenuOpen ? "active" : ""}`}
            >
              <Link
                to={"/"}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={closeMobileMenu}
              >
                <li>Home</li>
              </Link>
              <Link
                to={"/blog"}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={closeMobileMenu}
              >
                <li>Blog</li>
              </Link>
              <Link
                to={"/projects"}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={closeMobileMenu}
              >
                <li>Projects</li>
              </Link>
              <Link
                to={"/contact"}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={closeMobileMenu}
              >
                <li>Contact</li>
              </Link>
            </ul>
          </div>

          <div className="icons-container">
            <a
              href="https://github.com/on-xx"
              target="_blank"
              className="icon-menu"
            >
              <FeatherIcon icon={"github"} color={"#000"} />
            </a>
            <a
              href="https://www.linkedin.com/in/philiponchoi"
              target="_blank"
              className="icon-menu"
            >
              <FeatherIcon icon={"linkedin"} color={"#000"} />
            </a>
            {/* <a href="#" className="icon-menu theme-btn">
              <FeatherIcon icon={"moon"} color={"#fff"} />
            </a> */}
          </div>
        </div>
      </div>
      <div className="top-background-img">
        <img src={blurBg} alt="" />
      </div>
    </>
  );
};

export default Header;
