import CodeBlock from "./CodeBlock";

const PostSection = ({
  section_title,
  section_subtitle,
  section_image,
  section_mobile,
  section_content,
  section_bullets,
  section_codes,
}) => {
  return (
    <div className="post-section-container">
      {section_title && <h1>{section_title}</h1>}

      {section_subtitle && <h2>{section_subtitle}</h2>}

      {section_image && (
        <div className="section-image-container">
          <img src={section_image} alt="" />
        </div>
      )}
      {section_mobile && (
        <div className="section-image-mobile-container">
          <img src={section_mobile} alt="" />
        </div>
      )}

      {section_content && <p className="font-georgia">{section_content}</p>}

      {section_bullets && (
        <ul className="bullet-items-container">
          {section_bullets.map((item, index) => (
            <li className="bullet-item font-georgia">
              {index + 1}. {item}
            </li>
          ))}
        </ul>
      )}

      {section_codes && (
        <div className="code-block-container">
          <CodeBlock code={section_codes} />
        </div>
      )}
    </div>
  );
};

export default PostSection;
