import { Link } from "react-router-dom";
import blogPostImage1 from "../image/post-image-1.png";

const BlogPostCard = ({ img, tag, title, subtitle, date, length, id }) => {
  return (
    <Link
      to={`/blogs/detail/${id}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <div className="blog-post-card-image">
        <img src={img} alt="" />
      </div>
      <div className="blog-post-card-content">
        <div className="blog-tags-container">
          <h5 className="blog-tag">{tag}</h5>
        </div>
        <div className="blog-title-container">
          <h1 className="blog-main-title">{title}</h1>
          <h3 className="blog-sub-title font-georgia">{subtitle}</h3>
        </div>
        <div className="blog-addinfo-container">
          <p className="blog-addinfo-date">{date}</p>
          <p className="blog-addinfo-length">{length}</p>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostCard;
