import LineBreak from "../components/LineBreak";
import SectionTitle from "../components/SectionTitle";
import profile from "../image/profile.png";
import topPostImage from "../image/post-image-1.png";
import blogPostImage1 from "../image/post-image-2.png";
import blogPostImage2 from "../image/post-image-3.png";
import blogPostImage3 from "../image/post-image-4.png";
import projectImage1 from "../image/project-gogosing/project-1.png";
import projectImage2 from "../image/project-onstamp/project-onstamp.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import BlogPostCard from "../components/BlogPostCard";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { projectData, blogData } from "../data";

const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const bottomPosts = blogData.filter((item) => [2, 3, 4].includes(item.id));

  return (
    <div id="home-page">
      <div className="home-page container">
        <div className="hero-container">
          <div className="hero-text-container">
            <h1 className="hero-main-text">
              I’m <span className="point-text">Choi On</span>. My mission is to
              <br />
              translate user-centred <br />
              designs into problem solutions <br />
              as a software engineer.
            </h1>
            <p className="hero-sub-text font-georgia">
              “Too many thoughts and too many problems,
              <br /> But programming always makes it simple.”
            </p>
          </div>
          <div className="hero-image-container">
            <img src={profile} alt="" />
          </div>
        </div>
        <LineBreak />
        <SectionTitle
          mainTitle={"Recent Blog Posts"}
          subTitle={"Read more posts on the blog"}
          link={"/blog"}
        />
        <div className="blog-section-container">
          <Link
            to={"/blogs/detail/1"}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <div className="blog-post-top">
              <div className="blog-post-top-container">
                <div className="blog-top-post-image">
                  <img src={blogData[0].main_image} alt="" />
                </div>

                <div className="blog-top-post-content">
                  <div className="blog-tags-container">
                    <h5 className="blog-tag">{blogData[0].category}</h5>
                  </div>
                  <div className="blog-title-container">
                    <h1 className="blog-main-title">{blogData[0].title}</h1>
                    <h3 className="blog-sub-title font-georgia">
                      {blogData[0].subtitle}
                    </h3>
                  </div>
                  <div className="blog-addinfo-container">
                    <p className="blog-addinfo-date">{blogData[0].date}</p>
                    <p className="blog-addinfo-length">{blogData[0].length}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div className="blog-post-bottom">
            <div className="blog-post-bottom-container">
              {bottomPosts.map((item) => (
                <div className="blog-post-card-container">
                  <BlogPostCard
                    img={item.main_image}
                    tag={item.category}
                    title={item.title}
                    subtitle={item.subtitle}
                    date={item.date}
                    length={item.length}
                    id={item.id}
                  />
                </div>
              ))}
              {/* <div className="blog-post-card-container">
                <BlogPostCard
                  img={blogPostImage1}
                  tag1={"GOGOSING"}
                  tag2={"REACT NATIVE"}
                />
              </div>
              <div className="blog-post-card-container">
                <BlogPostCard
                  img={blogPostImage2}
                  tag1={"GOGOSING"}
                  tag2={"REACT NATIVE"}
                />
              </div>
              <div className="blog-post-card-container">
                <BlogPostCard
                  img={blogPostImage3}
                  tag1={"GOGOSING"}
                  tag2={"REACT NATIVE"}
                />
              </div> */}
            </div>
          </div>
        </div>
        <LineBreak />
        <SectionTitle
          mainTitle={"Recent Projects"}
          subTitle={"Read more posts on the projects"}
          link={"/projects"}
        />
        <div className="projects-section-container">
          <Link to={"/projects/detail/1"} style={{ textDecoration: "none" }}>
            <div className="project-card-container">
              <div className="project-card-image">
                <img src={projectImage1} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[0].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[0].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={"/projects/detail/3"} style={{ textDecoration: "none" }}>
            <div className="project-card-container row-reverse">
              <div className="project-card-image">
                <img src={projectImage2} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[2].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[2].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
