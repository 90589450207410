import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import postImage1 from "../image/post-image-1.png";

import BlogPostCard from "../components/BlogPostCard";
import blogPostImage1 from "../image/post-image-2.png";
import blogPostImage2 from "../image/post-image-3.png";
import blogPostImage3 from "../image/post-image-4.png";
import { useEffect } from "react";
import { blogData } from "../data";
import { Link } from "react-router-dom";

const BlogPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const filteredBlogPosts = blogData.filter((item) => item.id !== 1);

  return (
    <div id="blog-page">
      <div className="blog-page container">
        <div className="hero-container">
          <h3 className="hero-sub-title">Blog</h3>
          <h1 className="hero-title">
            “Empowering Your Programming Journey:
            <br /> Insightful and Practical Posts Just for You”
          </h1>
        </div>
        <Link
          to={"/blogs/detail/1"}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className="featured-post-container">
            <div className="featured-post-image">
              <img src={blogData[0].main_image} alt="" />
            </div>
            <div className="featured-post-content">
              <h5>Featured Post</h5>
              <div className="blog-tags-container">
                <h5 className="blog-tag">{blogData[0].category}</h5>
              </div>
              <h1 className="featured-post-title">{blogData[0].title}</h1>
              <h3 className="featured-post-sub-title font-georgia">
                {blogData[0].subtitle}
              </h3>
              <div className="blog-addinfo-container">
                <p className="blog-addinfo-date">{blogData[0].date}</p>
                <p className="blog-addinfo-length">{blogData[0].length}</p>
              </div>
            </div>
          </div>
        </Link>

        <div className="blog-posts-grid-container">
          {filteredBlogPosts.map((item) => (
            <div className="blog-post-card-container">
              <BlogPostCard
                img={item.main_image}
                tag={item.category}
                title={item.title}
                subtitle={item.subtitle}
                date={item.date}
                length={item.length}
                id={item.id}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
