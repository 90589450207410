import SyntaxHighlighter from "react-syntax-highlighter";
import {
  atomOneDark,
  atelierSeasideDark,
  atelierSulphurpoolDark,
  codepenEmbed,
  atelierEstuaryDark,
  dark,
  stackoverflowDark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeBlock = ({ code }) => {
  return (
    <SyntaxHighlighter
      language="jsx"
      style={stackoverflowDark}
      customStyle={{
        padding: "25px",
        width: "100%",
        borderRadius: "20px",
      }}
    >
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;
