import { useEffect } from "react";
import PostSection from "../components/PostSection";
import { projectData } from "../data";
import { useParams } from "react-router-dom";

const PostDetailScreen = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  // Convert id to a number
  const projectId = parseInt(id, 10);

  // Find the project with the matching id
  const selectedProject = projectData.find(
    (project) => project.id === projectId
  );

  return (
    <div id="post-detail">
      <div className="post-detail container">
        <div className="post-detail-hero-section-container">
          <h1>{selectedProject.title && selectedProject.title}</h1>
          <p>{selectedProject.subtitle && selectedProject.subtitle}</p>
          <h3>{selectedProject.date && selectedProject.date}</h3>
        </div>
        <div className="post-detail-main-image-container">
          <img src={selectedProject.main_image} alt="" />
        </div>

        <div className="post-detail-main-content-container">
          {selectedProject.sections.map((item) => (
            <PostSection
              section_title={item.section_title}
              section_subtitle={item.section_subtitle}
              section_image={item.section_image}
              section_mobile={item.section_mobile}
              section_content={item.section_content}
              section_bullets={item.section_bullets}
              section_codes={item.section_codes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostDetailScreen;
