import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import projectImage1 from "../image/project-gogosing/project-1.png";
import projectImage2 from "../image/project-onstamp/project-onstamp.png";
import projectImage3 from "../image/project-alomalo/project-alomalo.png";
import projectImage4 from "../image/project-obba/project-obba.png";
import projectImage5 from "../image/project-singmate/project-singmates.png";
import { projectData } from "../data";

import { Link } from "react-router-dom";
import { useEffect } from "react";

const ProjectsPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="projects-page">
      <div className="projects-page container">
        <div className="hero-container">
          <h3 className="hero-sub-title">Projects</h3>
          <h1 className="hero-title">
            Check Out The Selected Projects I Have Worked On
          </h1>
          <p className="hero-desc">
            Here you will find some of the personal and clients projects <br />
            that I created with each project containing its own case study
          </p>
        </div>
        <div className="projects-section-container">
          <Link to={"/projects/detail/1"} style={{ textDecoration: "none" }}>
            <div className="project-card-container">
              <div className="project-card-image">
                <img src={projectImage1} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[0].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[0].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={"/projects/detail/2"} style={{ textDecoration: "none" }}>
            <div
              className="project-card-container row-reverse"
              style={{ background: "#377AB0" }}
            >
              <div className="project-card-image">
                <img src={projectImage3} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[1].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[1].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={"/projects/detail/3"} style={{ textDecoration: "none" }}>
            <div
              className="project-card-container "
              style={{ background: "#6074B4" }}
            >
              <div className="project-card-image">
                <img src={projectImage2} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[2].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[2].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to={"/projects/detail/4"} style={{ textDecoration: "none" }}>
            <div
              className="project-card-container row-reverse"
              style={{ background: "#856BAF" }}
            >
              <div className="project-card-image">
                <img src={projectImage4} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[3].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[3].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to={"/projects/detail/5"} style={{ textDecoration: "none" }}>
            <div
              className="project-card-container"
              style={{ background: "#A5609F" }}
            >
              <div className="project-card-image">
                <img src={projectImage5} alt="" />
              </div>
              <div className="project-card-content">
                <h1 className="project-card-content-main-title">
                  {projectData[4].title}
                </h1>
                <p className="project-card-content-detail">
                  {projectData[4].subtitle}
                </p>
                <div className="project-card-button-container">
                  <h3>Find Out More</h3>
                  <div className="arrow-container">
                    <FeatherIcon icon={"arrow-right"} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
