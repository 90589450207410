import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import blurBg from "../image/blur-bg.png";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer container">
        <h1>© 2023 Choi On</h1>
        <div className="footer-icons-container">
          <a
            className="footer-icon-menu"
            href="https://github.com/on-xx"
            target="_blank"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <div className="icon-container">
              <FeatherIcon icon={"github"} size={18} />
            </div>
            <h1>GitHub</h1>
          </a>
          <a
            className="footer-icon-menu"
            href="https://www.linkedin.com/in/philiponchoi"
            target="_blank"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <div className="icon-container">
              <FeatherIcon icon={"linkedin"} size={18} />
            </div>
            <h1>LinkedIn</h1>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
