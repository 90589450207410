import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";

const SectionTitle = ({ mainTitle, subTitle, link }) => {
  return (
    <div className="section-title-container">
      <h1 className="main-title-text">{mainTitle}</h1>
      <Link
        className="sub-title-container"
        style={{ textDecoration: "none" }}
        to={link}
      >
        <h3 className="sub-title-text">{subTitle}</h3>
        <div className="arrow-container">
          <FeatherIcon icon={"arrow-right"} size={17} color={"#087EA4"} />
        </div>
      </Link>
    </div>
  );
};

export default SectionTitle;
